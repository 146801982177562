import * as React from 'react';
import { debounce } from 'lodash';
import { SearchContainerViewProps } from '../../components/container';



const NavigationSearchContainerView: React.StatelessComponent<SearchContainerViewProps> = props => {
    const searchActivated: boolean = props.searchState.result != null && props.searchState.result.searchCount > 0 && props.searchIsOpen || props.searchState.noResultFound;
    const searchActivatedCssClass: string = searchActivated ? " nav-search-activated" : "";
    return (
        <div className={"nav-search" + searchActivatedCssClass} data-nav-search-container>
            <div className="nav-search-form">
                <div className="inline-input-form nav-search-form-inner">
                    <label className="visually-hidden" htmlFor="navSearch">Enter search term</label>
                    <input id="navSearch" className="nav-search-form-text" type="text" value={props.searchState.searchKeyword} placeholder={props.text.searchTextboxPlaceHolder}
                        onChange={(eve) => props.handleKeyUp(eve)} onKeyDown={(eve) => props.handleKeyDown(eve)} onFocus={(evt) => {
                            if (props.searchState.searchKeyword == "") {
                                props.openSearch();
                            }
                        }}
                    />
                    <button className="nav-search-submit un-button" onClick={() => props.searchSubmit()}> {props.text.searchCTAText}</button>
                    <svg focusable="false" role="presentation" aria-hidden="true" className="svg-icon nav-search-icon">
                        <use xlinkHref="#icon-search" />
                    </svg>
                </div>
                {
                    searchActivated ?
                        <button className="btn-link nav-search-close" data-nav-search-close onClick={() => {
                            props.closeSearch();
                        }}>
                            <span className="nav-search-close-text">
                                Close
                            </span>
                            <svg focusable="false" role="presentation" aria-hidden="true" className="svg-icon">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-x"></use>
                            </svg>
                        </button>
                    :
                    null
                }
            </div>

            {
                searchActivated
                    ?
                    <div className="nav-search-panel" data-nav-search-panel>
                        
                        <div className="nav-search-results">
                            <div className="nav-search-results-header">
                                <p className="h4-style">{props.text.suggestedSectionText}</p>
                                {
                                    props.searchState.noResultFound ?
                                        null
                                        :
                                        <a href={props.text.searchPageUrl + "?query=" + props.searchState.searchKeyword}>{props.text.viewResultPlaceholderText.replace("{0}", props.searchState.result.searchResults.length.toString())}</a>
                                }
                            </div>
                            
                            {
                                props.searchState.searchKeyword != ""
                                    ?
                                    <div className="nav-search-results-grid">
                                        {
                                            !props.searchState.noResultFound && props.searchState.result != null
                                                ?
                                                <>
                                                    {
                                                        props.searchState.result.searchResults.map(result => {
                                                            return (
                                                                <div className="nav-search-results-item">
                                                                    <a className="nav-search-results-link" href={result.pageRedirectUrl}>
                                                                        {
                                                                            result.imageUrl !== "" && result.imageUrl !== null
                                                                                ? 
                                                                                <div className="nav-search-results-image">
                                                                                    <img src={result.imageUrl} alt="" />
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        {
                                                                            result.contentType !== "" && result.contentType !== null
                                                                                ?
                                                                                <p className="nav-search-results-type">{result.contentType}</p>
                                                                                : null
                                                                        }
                                                                        {
                                                                            result.title !== "" && result.title !== null
                                                                                ?
                                                                                <p className="nav-search-results-title bold">{result.title}</p>
                                                                                : null
                                                                        }
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                                :
                                                <div className="nav-search-results-item nav-search-results-item-full">
                                                    <p className="large">{props.text.noResultsFoundText.replace("{0}", props.searchState.searchKeyword)}</p>
                                                </div>
                                        }
                                    </div>
                                    :
                                    <div className="nav-search-suggested">
                                        {
                                            props.text.suggestedSearch != null && props.text.suggestedSearch.length > 0 && props.searchState.showSuggestedSearch ?
                                                <ul className="nav-search-buttons-list">
                                                    {
                                                        props.text.suggestedSearch.map(result => {
                                                            return (
                                                                <li>
                                                                    <a className="btn-primary btn-wide" href={result.href}>
                                                                        <svg focusable="false" role="presentation" aria-hidden="true" className="svg-icon svg-on-left">
                                                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-search"></use>
                                                                        </svg>
                                                                        {result.title}
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                :
                                                null
                                        }
                                    </div>

                            }
                        </div>
                    </div>
                    :
                    null
            }
            
        </div>

    )
}

export default NavigationSearchContainerView;