import { ISearchText, defaultSearchText } from "../../classes/search";
import { ISearchStateAction, ActionType } from "../../classes/search-actions";


/**
 * Returns the new search state given a particular action. This where all of the application
 * logic resides.
 */
export default function searchTextReducer(state: ISearchText = defaultSearchText, action: ISearchStateAction) {
    switch (action.type) {
        case ActionType.SetSearchObject: {
            return { ...state, ...action.value } as ISearchText;
        }
        default:
            return state;
    }
}
