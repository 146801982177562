import { ActionType } from "../../classes/search-actions";
import { ISearchText } from '../../classes/search';



export function setSearchKeyword(searchKeyword: string) {
    return {
        type: ActionType.SetSearchKeyword,
        value: searchKeyword
    }
}

export function setSearchResults(searchResult) {
    return {
        type: ActionType.SetSearchResult,
        value: searchResult
    }
}
export function setNoResults(noResult: boolean) {
    return {
        type: ActionType.SetNoResult,
        value: noResult
    }
}


export function showSuggestedSearches(showSuggested: boolean) {
    return {
        type: ActionType.ShowSuggestedSearch,
        value: showSuggested
    }
}

export function setSearchObject(search: ISearchText) {
    return {
        type: ActionType.SetSearchObject,
        value: search
    }
}
