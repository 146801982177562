import * as React from 'react';
import { connect, Dispatch } from 'react-redux';
import { lockBody, unlockBody } from '../../../Static/javascript/Content/helpers/lockBody';
import { ISearchState, ISearchText } from '../classes/search';
import { ISearchAppState } from '../redux-state';
import { getSearchResults } from '../redux-state/actions/search-api.actions';
import { setSearchKeyword, showSuggestedSearches } from '../redux-state/actions/search.actions';
import NavigationSearchContainerView from '../views/library/navigation-search-container.view';

interface StateToProps {
    searchState: ISearchState;
    text: ISearchText;
    searchKeyword: string;
}

interface DispatchToProps {
    searchForResults: (keyword: string) => void;
    setSearchKeyword: (keyword: string) => void;
    showSuggestedSearches: (showSuggested: boolean) => void;
}

interface ListPerformanceViewProps {
    handleKeyUp: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleCssClassUpdates: (searchFieldActivated: boolean) => void;
    closeSearch: () => void;
    openSearch: () => void;
    searchIsOpen: boolean;
    searchSubmit: () => void;
}

interface SearchContainerBaseState {
    typingTimer: any;
    doneTypingInterval: number;
}

const InitialState: SearchContainerBaseState ={
    typingTimer: null,
    doneTypingInterval: 300
}

export type SearchContainerProps = StateToProps & DispatchToProps;
export type SearchContainerViewProps = StateToProps & DispatchToProps & ListPerformanceViewProps;
export class SearchContainerBase extends React.Component<SearchContainerProps, SearchContainerBaseState>  {
    public readonly state = InitialState
    public navBg: HTMLElement = document.querySelector("[data-nav-background]") as HTMLElement;
    public navContainer: HTMLElement = document.querySelector('#nav-container') as HTMLElement;
    public navSearchContainer: HTMLElement | null;
    public currentBodyStyles: string;
    public mainNavigation = (window as any).mainNavigation;
    public searchIsOpen: boolean = false;

    constructor(props: SearchContainerProps) {
        super(props);
        this._getQueryResult();
    }

    _handleKeyup = event => {
        const searchKeyword = event.target.value;
        this.props.setSearchKeyword(searchKeyword);
        clearTimeout(this.state.typingTimer);
        this.state.typingTimer = setTimeout(() => {
            this._searchResults(searchKeyword);
        }, this.state.doneTypingInterval);
    };

    //Set the Query String Value to the search text field
    _getQueryResult = () => {
        if (window.location.href.indexOf(this.props.text.searchPageUrl) > -1) {
            var url = new URL(window.location.href);
            var queryValue = url.searchParams.get("query");
            this.props.setSearchKeyword(queryValue);
        }
    }
    _openSearch = () => {
        //if (this.mainNavigation.navIsShowing)
        //    this.mainNavigation.closeMenu();
        this._handleCssClassUpdates(true);
        this.props.showSuggestedSearches(true);
        this.searchIsOpen = true;
        this.navSearchContainer = document.querySelector('[data-nav-search-container]');
        document.body.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.which == 27 && this.searchIsOpen) { // Escape key
                this._closeSearch();
            }
            if (e.which == 9) { // Tab key. Show nav if tabbed into, or close menu if tabbed out of.
                const focusInNav = this.navSearchContainer.contains(document.activeElement);

                if (!focusInNav && this.searchIsOpen) {
                    this._closeSearch();
                }
            }
        });
        this.props.setSearchKeyword(this.props.searchState.searchKeyword);
        this._searchResults(this.props.searchState.searchKeyword);
    }

    _closeSearch = () => {
        this._searchResults("");
        this._handleCssClassUpdates(false);
        this.props.showSuggestedSearches(false);
        this.props.setSearchKeyword("");
        this.searchIsOpen = false;
    }

    _activateSearchStyles() {
        this.currentBodyStyles = lockBody();
        this.navContainer.classList.add('nav-search-activated');
        this.navContainer.classList.add('theme-dark');
        this.navContainer.classList.add('nav-menu-is-open');
    }

    _handleCssClassUpdates = (searchFieldActivated:boolean) => {
        if (searchFieldActivated)
            this._activateSearchStyles();
        else {
            this.navContainer.classList.remove('nav-search-activated');
            this.navContainer.classList.remove('theme-dark');
            this.navContainer.classList.remove('nav-menu-is-open');
            document.querySelector('body').removeAttribute('style');
            unlockBody(this.currentBodyStyles);
        }
    }
    _searchSubmit = () => {
        var url = "/search?query=" + this.props.searchKeyword;
        if (this.props.text.searchPageUrl != "") {
            url = this.props.text.searchPageUrl + "?query=" + this.props.searchKeyword;
        }
        window.location.href = url;
    }

    _handleKeydown = event => {
        clearTimeout(this.state.typingTimer);
        if (event.key === 'Enter') {
            this._searchSubmit();
        }
    }

    _searchResults = (searchKeyword: string) => {
        this.props.searchForResults(searchKeyword)
    }

    render() {
        return <NavigationSearchContainerView {...this.props} searchIsOpen={this.searchIsOpen} openSearch={this._openSearch} closeSearch={this._closeSearch} handleKeyUp={this._handleKeyup.bind(this)} searchSubmit={this._searchSubmit.bind(this)} handleKeyDown={this._handleKeydown.bind(this)} handleCssClassUpdates={this._handleCssClassUpdates} />;
    }
}

function mapStateToProps(state: ISearchAppState, ownProps: SearchContainerProps): StateToProps {
    return {
        searchState: state.searchState,
        text: state.searchText,
        searchKeyword: state.searchState.searchKeyword
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchToProps {
    return {
        searchForResults: (keyword: string) => dispatch(getSearchResults(keyword) as any),
        setSearchKeyword: (keyword: string) => dispatch(setSearchKeyword(keyword) as any),
        showSuggestedSearches: (showSuggested: boolean) => dispatch(showSuggestedSearches(showSuggested) as any)
    }
}

export default connect<StateToProps, DispatchToProps>(mapStateToProps, mapDispatchToProps)(SearchContainerBase);
