(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("_"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "_"], factory);
	else if(typeof exports === 'object')
		exports["Library"] = factory(require("react"), require("react-dom"), require("_"));
	else
		root["Library"] = factory(root["React"], root["ReactDOM"], root["_"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__8383__, __WEBPACK_EXTERNAL_MODULE__3949__, __WEBPACK_EXTERNAL_MODULE__6215__) {
return 