import axios from 'axios';
import { memoize } from 'lodash';
import { INavigationSearchResult } from '../classes/search';

export default class SearchApi {

    private static getSearchResultCache = memoize(SearchApi.getSearchResultsApi, (searchKeyword: string) => `searchkey-${searchKeyword}`);

    private static filtersSource = axios.CancelToken.source();

    public static getSearchResultsApi(searchKeyword: string): Promise<INavigationSearchResult> {

        var requestString = `/${process.env.SEARCH_API_ROUTE}/GetSearchResults?query=${searchKeyword}`;

        return axios.get<INavigationSearchResult>(requestString, {
            withCredentials: true,
            cancelToken: this.filtersSource.token
        }).then(response => response.data);
    }


    public static getSearchResults(searchKeyword: string): Promise<INavigationSearchResult> {
        return this.getSearchResultCache(searchKeyword);
    }



}
