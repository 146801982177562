import * as React from 'react';
import AppView from '../views/library/search-app.view';
import { ISearchAppState } from '../redux-state';
import { connect } from 'react-redux';

interface AppExternalProps {
  
}

interface StateToProps {
}

export type AppProps = AppExternalProps & StateToProps;

export class AppComponent extends React.Component<AppProps, any> {
    constructor(props: AppProps) {
        super(props);
    }

    componentDidMount() {
        // Remeasuring the height of the nav after the search bar exists to offset fixed nav properly
        const navContainer = document.querySelector('#nav-container') as HTMLElement;
        if (navContainer) {
            document.documentElement.style.setProperty('--nav-clearance', `${navContainer.offsetHeight}px`);
        }
    }

    render() {
        return (
            <AppView {...this.props} />
        )
    }
}

function mapStateToProps(state: ISearchAppState, ownProps: AppExternalProps): StateToProps {
    return {
    }
}

export default connect<StateToProps>(mapStateToProps)(AppComponent);
