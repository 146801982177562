import { ISearchState, defaultSearchState, defaultResult } from "../../classes/search";
import { ISearchStateAction, ActionType } from "../../classes/search-actions";


/**
 * Returns the new search state given a particular action. This where all of the application
 * logic resides.
 */
export default function searchReducer(state: ISearchState = defaultSearchState, action: ISearchStateAction) {
    switch (action.type) {
        case ActionType.SetSearchKeyword: {
            var searchKeyword = action.value;
            return { ...state, searchKeyword: searchKeyword } as ISearchState;
        }
        case ActionType.SetSearchResult: {
            return { ...state, result: action.value } as ISearchState;
        }

        case ActionType.SetNoResult: {
            return { ...state, noResultFound: action.value, result: defaultResult } as ISearchState;
        }
        case ActionType.ShowSuggestedSearch: {
            return { ...state, noResultFound: action.value, showSuggestedSearch: action.value } as ISearchState;
        }
        default:
            return state;
    }
}
