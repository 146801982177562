import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { logger } from '../../common/helpers/middleware';
import {  ISearchText, ISearchState } from '../classes/search';
import searchReducer from './reducers/search.reducer';
import searchTextReducer from './reducers/search-text.reducer';
import { setSearchObject } from './actions/search.actions';


export interface ISearchAppState {
    searchText: ISearchText,
    searchState: ISearchState
}

const appReducer = combineReducers({
    searchText: searchTextReducer,
    searchState: searchReducer
});

const appStore = createStore(appReducer, {}, applyMiddleware(thunk, logger));
appStore.dispatch(setSearchObject((window as any).searchObject) as any);
export { appStore };