import { ISearchState } from '../../classes/search'
import SearchApi from '../../common/search-api'
import { setSearchResults, setNoResults } from './search.actions'


export function getSearchResults(searchKeyword: string) {
    return (dispatch, getState: () => ISearchState) => {
        if (searchKeyword != "") {
            return SearchApi.getSearchResults(searchKeyword).then(apiResult => {
                if (apiResult.searchCount > 0) {
                    dispatch(setNoResults(false))
                    dispatch(setSearchResults(apiResult))
                }
                else {
                    dispatch(setNoResults(true))
                }
            }).catch(error => {
                dispatch(setNoResults(true))
            })
        }
        
    }
}
