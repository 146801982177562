import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { appStore } from './redux-state';
import App from './components/app';

render(
    <Provider store={appStore}>
        <App />
    </Provider>
    , document.getElementById("navigation-search-entry-js"));