/**
 * Describes the shape of a single action.
 */
export interface ISearchStateAction {
    type: ActionType;
    value?: any;
}



export class ActionType {

    static get SetNoResult() {
        return "SetNoResult";
    }
    static get SetSearchResult() {
        return "SetSearchResult";
    }
    static get SetSearchObject() {
        return "SetText";
    }
    static get ShowSuggestedSearch() {
        return "ShowSuggestedSearch";
    }
    static get SetSearchKeyword() {
        return "SearchResult";

    }
}