import * as React from 'react';
import { AppProps } from '../../components/app';
import SearchContainerBase from '../../components/container';


const AppView: React.StatelessComponent<AppProps> = props => {
    return (
        <SearchContainerBase 
        />
    );
}

export default AppView;