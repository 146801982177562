import moment from 'moment';

export interface ISearchState {
    searchKeyword: string;
    displaySuggested: boolean;
    result: INavigationSearchResult;
    noResultFound: boolean;
    showSuggestedSearch: boolean;
}

export const defaultSearchState: ISearchState = {
    searchKeyword : "",
    displaySuggested : false,
    result: null,
    noResultFound: false,
    showSuggestedSearch: false
};

export const defaultResult: INavigationSearchResult = {
    searchResults: [],
    searchCount: 0
}

export interface INavigationSearchResult {
    searchResults: Array<IBaseSearchResult>;
    searchCount: number;
}

export interface IBaseSearchResult {
    title: string;
    imageUrl: string;
    pageRedirectUrl: string;
    contentType: string;
}

export interface ISearchText {
    suggestedSectionText: string;
    viewResultPlaceholderText: string;
    noResultsFoundText: string
    searchCTAText: string;
    searchPageUrl: string;
    searchTextboxPlaceHolder: string;
    closeCTAText: string;
    suggestedSearch: Array<SuggestedItems>;
}

export const defaultSearchText: ISearchText = {
    suggestedSectionText: "Suggested",
    viewResultPlaceholderText: "{0} results found ",
    noResultsFoundText: "0 results found for {0} ",
    searchCTAText: "Search",
    searchPageUrl: "",
    searchTextboxPlaceHolder: "Search for tickets, events, and more",
    closeCTAText: "close",
    suggestedSearch: []
}

export interface SuggestedItems {
    href: string;
    title: string;
}